import * as z from 'zod';
import zodToJsonSchema from 'zod-to-json-schema';

import { Namespaces } from './mappingsPerNamespace';
import { documentsSchema } from './namespaces';

const mappingsConfigSchema = z
  .object({
    [Namespaces.Documents]: z.array(documentsSchema.keyof()),
  })
  .partial();

export const entityMappingsFeatureConfigSchema = z.union([
  z.object({
    enabled: z.literal(false),
  }),
  z.object({
    enabled: z.literal(true),
    mappings: mappingsConfigSchema,
  }),
]);

export type EntityMappingsConfig = z.infer<typeof entityMappingsFeatureConfigSchema>;
export type AvailableEntityMappings = z.infer<typeof mappingsConfigSchema>;

export const entityMappingsFeatureConfigJSONSchema = zodToJsonSchema(
  entityMappingsFeatureConfigSchema,
  'entityMappingsFeatureConfigJSONSchema'
);
