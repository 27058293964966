import { Localizable } from '../localization';
import { AgeRoundingType, DateUnit, Condition } from '../questionnaire';

export type ReflexiveConditionValue = {
  value: YesNoValue;
  type: ConditionBlueprintType.reflexive;
  targetNodeId: string;
} & CollectionConditionValue;

export type AgeRangeUnit = DateUnit.month | DateUnit.year | DateUnit.day;

export type AgeRangeConditionValue = {
  value: {
    minAge: number;
    maxAge: number;
    unit: AgeRangeUnit;
  };
  targetBirthdateNodeId: string;
  type: ConditionBlueprintType.ageRange;
  roundingType?: AgeRoundingType;
} & CollectionConditionValue;

export type CharacterCountInBetweenConditionValue = {
  value: { minLength?: number; maxLength?: number };
  targetNodeId: string;
  type: ConditionBlueprintType.characterCountInBetween;
} & CollectionConditionValue;

export enum InstancesCountMethod {
  addLengthOfArray = 'addLengthOfArray',
  addOneIfExists = 'addOneIfExists',
  addValue = 'addValue',
  addOneIfValueEquals = 'addOneIfValueEquals',
  addNumberIfValueEquals = 'addNumberIfValueEquals',
}

export type NodeIdToCount = {
  nodeId: string;
  repeatableCollectionNodeId?: string;
} & (
  | {
      countMethod:
        | InstancesCountMethod.addLengthOfArray
        | InstancesCountMethod.addOneIfExists
        | InstancesCountMethod.addValue;
    }
  | {
      countMethod: InstancesCountMethod.addOneIfValueEquals;
      compareValue: unknown;
    }
  | {
      countMethod: InstancesCountMethod.addNumberIfValueEquals;
      numberToAdd: number;
      compareValue: unknown;
    }
);

export type InstancesCountConditionValue = {
  type: ConditionBlueprintType.instancesCount;
  limitOf?: number;
  minimumOf?: number;
  nodeIdsToAdd: NodeIdToCount[];
  nodeIdsToSubtract: NodeIdToCount[];
} & CollectionConditionValue;

export type JointProductAgeRangeConditionValue = {
  type: ConditionBlueprintType.jointProductAgeRange;
  jointProductNodeIds: {
    surrogateId: string;
    productTypeId: string;
  }[];
  insuredBirthdayNodeId: string;
  repeatableInsuredCollectionId: string;
  value: {
    maxAge: number;
    unit: AgeRangeUnit;
  };
  roundingType?: AgeRoundingType;
} & CollectionConditionValue;

export type BodyMassIndexRangeConditionValue = {
  type: ConditionBlueprintType.bmiRange;
  value: { minBMI: number; maxBMI: number };
  heightNodeId: string;
  weightNodeId: string;
  heightUnit: 'cm' | 'inch';
  weightUnit: 'lb' | 'kg';
} & CollectionConditionValue;

export type MathOperatorConditionValue = {
  value?: number;
  nodeIdOfValue?: string;
  nodeIds: string[];
  type: ConditionBlueprintType.mathOperator;
  operator: NumberComparisonConditionOperator;
  mathOperator: MathConditionOperator;
} & CollectionConditionValue;

export type EqualityConditionValue = {
  value?: string | number | boolean;
  type: ConditionBlueprintType.equality;
  isEqual: boolean;
  targetNodeId: string;
  nodeIdOfValue?: string;
} & CollectionConditionValue;

export type EmptinessConditionValue = {
  type: ConditionBlueprintType.emptiness;
  isEmpty: boolean;
  targetNodeId: string;
} & CollectionConditionValue;

export type MatchesConditionValue = {
  value: string[];
  type: ConditionBlueprintType.matches;
  quantifier: MatchesConditionPropertyQuantifier;
  targetNodeId: string;
} & CollectionConditionValue;

export type NumberComparisonConditionValue = {
  type: ConditionBlueprintType.numberComparison;
  operator: NumberComparisonConditionOperator;
  value?: number;
  targetNodeId: string;
  nodeIdOfValue?: string;
} & CollectionConditionValue;

export type DateComparisonConditionValue = {
  type: ConditionBlueprintType.dateComparison;
  startDateNodeId: string;
  endDateNodeId: string;
  value: number;
  unit: DateUnit;
  operator: NumberComparisonConditionOperator;
} & CollectionConditionValue;

export type PercentOfComparisonConditionValue = {
  type: ConditionBlueprintType.percentOf;
  targetNodeId: string;
  percent: number;
  operator: NumberComparisonConditionOperator;
  value?: number;
  nodeIdOfValue?: string;
} & CollectionConditionValue;

export type CountEqualConditionValue = {
  targetNodeId: string;
  type: ConditionBlueprintType.countEqual;
  value: number;
  controlValue: number | string | boolean;
  operator: NumberComparisonConditionOperator;
} & CollectionConditionValue;

export type LastIncidentDateConditionValue = {
  type: ConditionBlueprintType.lastIncidentDate;
  targetNodeId: string;
  value: number;
  unit: DateUnit;
  operator: NumberComparisonConditionOperator;
} & CollectionConditionValue;

export type EngineConditionValue = {
  type: ConditionBlueprintType.engineCondition;
  condition: Condition;
} & CollectionConditionValue;

export type BlueprintSingleConditionValue =
  | ReflexiveConditionValue
  | AgeRangeConditionValue
  | CharacterCountInBetweenConditionValue
  | BodyMassIndexRangeConditionValue
  | MathOperatorConditionValue
  | EqualityConditionValue
  | EmptinessConditionValue
  | MatchesConditionValue
  | NumberComparisonConditionValue
  | EngineConditionValue
  | LastIncidentDateConditionValue
  | DateComparisonConditionValue
  | CountEqualConditionValue
  | PercentOfComparisonConditionValue
  | InstancesCountConditionValue
  | JointProductAgeRangeConditionValue;

type CollectionConditionValue = {
  collectionOperators?: Record<string, BlueprintCollectionOperator>;
};

export type BlueprintConditionValue = BlueprintSingleConditionValue | BlueprintConditionsValue;

export type BlueprintConditionsValue = {
  conditions: BlueprintConditionValue[];
  booleanOperator?: 'or' | 'and' | 'not';
};

export type BlueprintConditionsValueWithMessage = {
  id?: string;
  conditions: BlueprintConditionValue;
  message: Partial<Localizable>;
};

export enum ConditionBlueprintType {
  reflexive = 'reflexiveCondition',
  ageRange = 'ageRangeCondition',
  bmiRange = 'bmiRangeCondition',
  equality = 'equalityCondition',
  emptiness = 'emptinessCondition',
  matches = 'matchesCondition',
  numberComparison = 'numberComparisonCondition',
  mathOperator = 'mathOperator',
  lastIncidentDate = 'lastIncidentDateCondition',
  dateComparison = 'dateComparisonCondition',
  characterCountInBetween = 'characterCountInBetween',
  countEqual = 'countEqual',
  percentOf = 'percentOf',
  instancesCount = 'instancesCount',
  jointProductAgeRange = 'jointProductAgeRange',
  engineCondition = 'engineCondition',
}

export enum NumberComparisonConditionOperator {
  equal = 'equal',
  lessThan = 'lessThan',
  lessThanOrEqual = 'lessThanOrEqual',
  greaterThan = 'greaterThan',
  greaterThanOrEqual = 'greaterThanOrEqual',
  multipleOf = 'multipleOf',
}

export enum MathConditionOperator {
  sum = 'sum',
  subtract = 'subtract',
  multiply = 'multiply',
}

export enum MatchesConditionPropertyQuantifier {
  any = 'any',
  none = 'none',
}

export enum YesNoValue {
  yes = 'yes',
  no = 'no',
}

export enum BlueprintCollectionOperator {
  thisItem = 'thisItem',
  some = 'some',
  none = 'none',
  every = 'every',
}

export type CriterionMetadata = {
  isNumberCriteria?: boolean;
  isTextCriteria?: boolean;
  isSelectOptionCriteria?: boolean;
  isReflexiveCriteria?: boolean;
  isDateCriteria?: boolean;
};

export function isBlueprintConditionsValue(
  blueprintConditionsValue: BlueprintConditionValue
): blueprintConditionsValue is BlueprintConditionsValue {
  return blueprintConditionsValue && !!(blueprintConditionsValue as BlueprintConditionsValue).conditions;
}
