import * as z from 'zod';

import { KeepTypeAlias } from '../utilities';
import { Namespaces, mappingsPerNamespaceSchema, MappingsSchema, NamespacesSchema } from './schemas';

const createParserForNamespace = <A extends string, B extends [string, ...string[]]>(
  namespace: A,
  mapping: z.ZodEnum<B>
): z.ZodObject<{
  namespace: z.ZodLiteral<A>;
  mapping: z.ZodEnum<B>;
  customCode: z.ZodString;
  questionnaireVersionId: z.ZodString;
}> => {
  return z.object({
    namespace: z.literal(namespace),
    mapping,
    customCode: z.string().min(1, { message: 'Your code is empty' }),
    questionnaireVersionId: z.string().uuid(),
  });
};

// TODO replace the following with a Zod union of all namespace parser
export const entityMappingDefinitionSchema = createParserForNamespace(
  Namespaces.Documents,
  mappingsPerNamespaceSchema.shape[Namespaces.Documents].keyof()
).required();

export const entityMappingDefinitionsArraySchema = z.array(entityMappingDefinitionSchema);

export type EntityMappingDefinition = KeepTypeAlias<z.infer<typeof entityMappingDefinitionSchema>>;

export const entityMappingDefinitionSearchParams = z.object({
  questionnaireVersionId: z.string().uuid(),
  mapping: MappingsSchema,
  namespace: NamespacesSchema,
});

export type GetEntityMappingDefinitionParams = z.infer<typeof entityMappingDefinitionSearchParams>;

export function isCompleteEntityMappingDefinitionRequestOptions(
  mappingOptions: Partial<GetEntityMappingDefinitionParams>
): mappingOptions is GetEntityMappingDefinitionParams {
  return Boolean(mappingOptions.mapping && mappingOptions.namespace && mappingOptions.questionnaireVersionId);
}
