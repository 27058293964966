export enum Permission {
  // TODO: Remove ApplicationLaunch once every carrier are in prod for at least 1 day with the new
  // ApplicationLaunchMe and ApplicationLaunchAll permissions.
  ApplicationLaunch = 'application:launch',
  ApplicationLaunchMe = 'application:launch:me',
  ApplicationLaunchAll = 'application:launch:all',
  ApplicationRead = 'application:read',
  ApplicationCreate = 'application:create',
  ApplicationUpdate = 'application:update',
  ApplicationSendDeclaration = 'application:send-declaration',
  ApplicationResetSubmittedStatus = 'application:reset-submitted-status',

  ApplicationSignatureRead = 'applicationSignature:read',

  ESignNotificationRead = 'esignNotification:read',
  ESignNotificationUpdate = 'esignNotification:update',
  ESignNotificationRemove = 'esignNotification:remove',

  ESignSettingCreate = 'eSignSetting:create',
  ESignSettingUpdate = 'eSignSetting:update',
  ESignSettingRead = 'eSignSetting:read',
  ESignSettingRemove = 'eSignSetting:remove',

  InsuranceFirmCreate = 'insuranceFirm:create',
  InsuranceFirmUpdate = 'insuranceFirm:update',
  InsuranceFirmRead = 'insuranceFirm:read',

  InsuranceProductCreate = 'insuranceProduct:create',
  InsuranceProductUpdate = 'insuranceProduct:update',
  InsuranceProductRead = 'insuranceProduct:read',

  InsuranceProductPricingCreate = 'InsuranceProductPricingCreate:create',

  LeadCreate = 'lead:create',
  LeadDelete = 'lead:delete',
  LeadEditContacted = 'lead:edit:contacted',
  LeadArchive = 'lead:archive',
  LeadReadMe = 'lead:read:me',
  LeadReadGroup = 'lead:read:group',
  LeadReadAll = 'lead:read:all',
  LeadInvitePublic = 'lead:invite:public',
  LeadInvitePrivate = 'lead:invite:private',
  LeadInviteEmail = 'lead:invite:email',
  LeadAssignRead = 'lead:assign:read',
  LeadAssignMe = 'lead:assign:me',
  LeadAssignGroup = 'lead:assign:group',
  LeadAssignAll = 'lead:assign:all',
  LeadUnassignMe = 'lead:unassign:me',
  LeadUnassignAll = 'lead:unassign:all',

  PdfSummaryRead = 'pdfSummary:read',

  SignatureRequestCreate = 'signatureRequest:create',
  SignatureRequestRead = 'signatureRequest:read',
  SignatureRequestRemove = 'signatureRequest:remove',
  SignatureRequestUpdate = 'signatureRequest:update',
  SignatureRequestEventRead = 'signatureRequestEvent:read',
  SubmissionUpdate = 'submission:update',

  UserEditSelf = 'user:edit:self',
  UserEditAll = 'user:edit:all',
  UserCreate = 'user:create',
  UserBulkCreate = 'user:bulk:create',
  UserCreateSuperAdmin = 'user:create:superAdmin',
  UserRead = 'user:read',
  UserSyncAll = 'user:sync:all',
  UserClearMetadata = 'user:metadata:remove',
  AdminToolsRead = 'adminTools:read',

  // Used by advisor back office (UserRole.support)
  BackOfficeSearch = 'backOffice:search',
  BackOfficeUpdate = 'backOffice:update',

  QuestionnaireCreate = 'questionnaire:create',
  QuestionnaireRead = 'questionnaire:read',
  QuestionnaireUpdate = 'questionnaire:update',
  QuestionnairePreview = 'questionnaire:preview',
  QuestionnairePartRead = 'questionnairePart:read',

  SalesDecisionRuleCreate = 'salesDecisionRule:create',
  SalesDecisionRuleRead = 'salesDecisionRule:read',
  SalesDecisionRuleUpdate = 'salesDecisionRule:update',
  SalesDecisionRuleRemove = 'salesDecisionRule:remove',

  ThemeCreate = 'theme:create',
  ThemeRead = 'theme:read',
  ThemeUpdate = 'theme:update',

  SettingsCreate = 'settings:create',
  SettingsRead = 'settings:read',
  SettingsUpdate = 'settings:update',

  ApplicationFileCreate = 'applicationFile:create',
  ApplicationFileRead = 'applicationFile:read',
  ApplicationFileUpdate = 'applicationFile:update',
  ApplicationFileRemove = 'applicationFile:remove',

  FileTemplateCreate = 'fileTemplate:create',
  FileTemplateRead = 'fileTemplate:read',
  FileTemplateUpdate = 'fileTemplate:update',
  FileTemplateRemove = 'fileTemplate:remove',

  FileTemplateRuleCreate = 'fileTemplateRule:create',
  FileTemplateRuleRead = 'fileTemplateRule:read',
  FileTemplateRuleUpdate = 'fileTemplateRule:update',
  FileTemplateRuleRemove = 'fileTemplateRule:remove',

  FileCreate = 'file:create',
  FileRead = 'file:read',
  FileUpdate = 'file:update',
  FileRemove = 'file:remove',

  EntityMappingUpdate = 'entityMapping:update',
  EntityMappingRead = 'entityMapping:read',
  EntityMappingDelete = 'entityMapping:delete',

  ThirdPartyIntegrationConfigurationEdit = 'thirdPartyIntegration:edit',
  ThirdPartyIntegrationConfigurationRead = 'thirdPartyIntegration:read',

  UnderwritingOutcomeReportsRead = 'underwritingOutcomeReports:read',

  BackOfficeApplicationsRead = 'backOfficeApplications:read',

  AgentLicenseCreate = 'agentLicense:create',
  AgentLicenseRead = 'agentLicense:read',

  ApplicationMarketingDataExtract = 'applicationMarketingData:extract:create',
  ApplicationCopyCreate = 'applicationCopy:create',

  JetDecisionWidgetView = 'jetDecisionWidget:view',

  PlatformConfigurationSync = 'platformConfiguration:sync',
  PlatformConfigurationRead = 'platformConfiguration:read',
}
