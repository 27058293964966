import { OutcomeCode } from '../questionnaire/outcome';
import { UnderwritingDataProvider } from '../underwritingData';

export type ApplicationOutcome = {
  id: string;
  applicationId: string;
  participantId: string;
  outcomeCode: OutcomeCode;
  type: ApplicationOutcomeType;
  provider: UnderwritingDataProvider;
};

export type ApplicationOutcomesPerParticipantId = Record<string, ApplicationOutcome[]>;

// TODO: Update the dashed case value to camel case
export enum ApplicationOutcomeType {
  SalesDecision = 'sales-decision',
  MedicalHistory = 'medicalHistory',
  MIB = 'mib',
  IdentityCheck = 'identityCheck',
  MVR = 'mvr',
  Prescription = 'prescription',
}
