import { ESignSigner } from './eSignSigner';

export type OneSpanPackage = {
  id: string;
  eSignCeremonyId: string;
};

export type OneSpanSigner = {
  id: string;
  eSignSignerId: string;
  role: string;
};

export type OneSpanESignSigner = ESignSigner & {
  oneSpanId: string;
  oneSpanRole: string;
};

export enum OneSpanPackageErrorCode {
  ApplicationNotFoundError = 'OneSpanPackageApplicationNotFoundError',
  SendESignDocumentNotFoundError = 'OneSpanPackageSendESignDocumentNotFoundError',
  ESignCeremonyNotFoundError = 'OneSpanPackageESignCeremonyNotFoundError',
  NotAllRequiredFilesUploaded = 'OneSpanPackageNotAllRequiredFilesUploaded',
  CreateError = 'OneSpanPackageCreateError',
  RetrieveStoredFileError = 'OneSpanPackageRetrieveStoredFileError',
  SendESignDocumentNotUploadedError = 'OneSpanPackageSendESignDocumentNotUploadedError',
  SendStoredFileNotFoundError = 'OneSpanPackageSendStoredFileNotFoundError',
  SendESignDocumentFileNotFoundError = 'OneSpanPackageSendESignDocumentFileNotFoundError',
}

export enum OneSpanPackageErrorMessage {
  ApplicationNotFoundError = 'Failed to send OneSpan package: application not found.',
  SendESignDocumentNotFoundError = 'Failed to send OneSpan package: no eSignDocuments found.',
  NotAllRequiredFilesUploaded = 'Failed to send OneSpan package: all required files are not uploaded.',
  ESignCeremonyNotFoundError = 'Could not send OneSpanPackage: ESignCeremony not found',
  CreateError = 'Failed to create OneSpan package.',
  RetrieveStoredFileError = 'Failed to send OneSpan package: could not retrieve PDF for stored file.',
  SendESignDocumentNotUploadedError = 'Failed to send OneSpan package: ESignDocument not uploaded.',
  SendStoredFileNotFoundError = 'Failed to send OneSpan package: Stored file not found.',
  SendESignDocumentFileNotFoundError = 'Failed to send OneSpan package: no stored file found for generated eSignDocument.',
}
