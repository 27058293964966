import { ParticipantRole } from '../participant';

export enum ESignFieldSettingType {
  SIGNATURE = 'SIGNATURE',
}

export enum ESignFieldSettingPlacementType {
  RELATIVE = 'RELATIVE',
  ABSOLUTE = 'ABSOLUTE',
}

export enum ESignFieldSettingAnchorPointType {
  TOPLEFT = 'TOPLEFT',
  TOPRIGHT = 'TOPRIGHT',
  BOTTOMLEFT = 'BOTTOMLEFT',
  BOTTOMRIGHT = 'BOTTOMRIGHT',
}

type ESignFieldSettingBase = {
  id: string;
  signerSettingId: string;

  type: ESignFieldSettingType;

  // Points to a nodeId in the questionnaire.
  // Not currently used – for non-signature fields
  fieldBinding?: string;
  // Not currently used — for non-signature fields
  defaultValue?: string;

  top: number;
  left: number;
  width: number;
  height: number;
};

export type ESignFieldSettingAbsolute = ESignFieldSettingBase & {
  placementType: ESignFieldSettingPlacementType.ABSOLUTE;
  page: number;
};

export type ESignFieldSettingRelative = ESignFieldSettingBase & {
  placementType: ESignFieldSettingPlacementType.RELATIVE;
  anchorText: string;
  anchorPoint: ESignFieldSettingAnchorPointType;
  anchorIndex: number;
  anchorCharacterIndex: number;
};

export type ESignFieldSetting = ESignFieldSettingAbsolute | ESignFieldSettingRelative;

export type ESignSignerSetting = {
  id: string;
  fileTemplateId: string;
  participantRoleId: string;
  fieldSettings?: ESignFieldSetting[];
  participantRole?: ParticipantRole;
  isSignatureRequired: boolean;

  createdAt: Date;
};

export type FileTemplateRecipient = {
  id: string;
  fileTemplateId: string;
  participantRoleId: string;
  fieldSettings?: ESignFieldSetting[];
  participantRole?: ParticipantRole;
  isSignatureRequired: boolean;

  createdAt: Date;
};
