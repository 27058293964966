import { PdfDocumentType } from '../questionnaire';
import { StoredFile, StoredFileDocType } from './../models';

export const PdfTypeToStoredFileDocTypeLookup: Record<PdfDocumentType, StoredFileDocType | undefined> = {
  [PdfDocumentType.application]: StoredFileDocType.ApplicationPdf,
  [PdfDocumentType.advisorsReport]: StoredFileDocType.AdvisorsReport,
  [PdfDocumentType.carrierReport]: StoredFileDocType.CarrierReport,
  [PdfDocumentType.external]: undefined,
  [PdfDocumentType.oneSpanEvidenceSummary]: undefined,
};

export const StoredFileDocTypeToPdfTypeLookup: Record<StoredFileDocType, PdfDocumentType | undefined> = {
  [StoredFileDocType.ApplicationPdf]: PdfDocumentType.application,
  [StoredFileDocType.AdvisorsReport]: PdfDocumentType.advisorsReport,
  [StoredFileDocType.CarrierReport]: PdfDocumentType.carrierReport,
  [StoredFileDocType.Attachment]: undefined,
  [StoredFileDocType.Illustration]: undefined,
  [StoredFileDocType.OneSpanEvidenceSummary]: undefined,
  [StoredFileDocType.NeedsAnalysisPdf]: undefined,
  [StoredFileDocType.ApplicationMarketingDataExtractCSV]: undefined,
};

export enum ESignDocumentStatus {
  /**
   * received request from Message Dispatcher
   */
  REQUESTED = 'REQUESTED',
  /**
   * PDF Generation in progress
   */
  GENERATING_PDF = 'GENERATING_PDF',
  /**
   * PDF Generated and uploaded
   */
  UPLOADED = 'UPLOADED',

  PDF_GENERATION_FAILED = 'PDF_GENERATION_FAILED',
}

export enum ESignDocumentType {
  GENERATED = 'GENERATED',
  UPLOADED = 'UPLOADED',
}

type BaseESignDocument = {
  id: string;
  ceremonyId: string;
  originalStoredFileId?: string;
  completedStoredFileId?: string;
  status: ESignDocumentStatus;
  type: ESignDocumentType;
  createdAt: Date;
  updatedAt: Date;
};

export type ESignDocument = BaseESignDocument &
  (
    | {
        type: ESignDocumentType.GENERATED;
        status:
          | ESignDocumentStatus.GENERATING_PDF
          | ESignDocumentStatus.REQUESTED
          | ESignDocumentStatus.PDF_GENERATION_FAILED;
      }
    | {
        type: ESignDocumentType.UPLOADED | ESignDocumentType.GENERATED;
        status: ESignDocumentStatus.UPLOADED;
        fileTemplateId: string;
      }
  );

export type ESignDocumentWithOriginalStoredFile = ESignDocument & { originalStoredFile?: StoredFile };

export type ESignGeneratedDocument = StoredFileDocType.AdvisorsReport | StoredFileDocType.ApplicationPdf;
export type ESignUploadedDocument = StoredFileDocType.Illustration | StoredFileDocType.Attachment;

export const generatedDocuments: ESignGeneratedDocument[] = [
  StoredFileDocType.AdvisorsReport,
  StoredFileDocType.ApplicationPdf,
];
export const uploadedDocuments: ESignUploadedDocument[] = [
  StoredFileDocType.Illustration,
  StoredFileDocType.Attachment,
];
