export enum Language {
  en = 'en',
  fr = 'fr',
}

export type Localizable = Record<Language, string>;
export type LocalizableList = Record<Language, string[]>;

export enum LanguageRegion {
  frCa = 'fr-CA',
  enCa = 'en-CA',
}
