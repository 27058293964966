// NOTE: Please update the README.md in shared/questionnaire-engine if this is modified.
export enum BooleanOperator {
  and = 'and',
  or = 'or',
  not = 'not',
}

// NOTE: Please update the README.md in shared/questionnaire-engine if this is modified.
export enum ComparisonOperator {
  isEmpty = 'isEmpty',
  notEmpty = 'notEmpty',
  equal = 'equal',
  notEqual = 'notEqual',
  greaterThan = 'greatherThan',
  greaterThanOrEqual = 'greaterThanOrEqual',
  lessThan = 'lessThan',
  lessThanOrEqual = 'lessThanOrEqual',
  inRange = 'inRange', // not supported in validation conditions
  matchesAny = 'matchesAny',
  matchesNone = 'matchesNone',
  multipleOf = 'multipleOf',
  characterCountBetween = 'characterCountBetween',
}

// NOTE: Please update the README.md in shared/questionnaire-engine if this is modified.
export enum CollectionOperator {
  some = 'some',
  none = 'none',
  every = 'every',
  count = 'count',
}
