export enum MIBOutcome {
  noMatch = 'noMatch',
  HIT = 'HIT',
  TRY = 'TRY',
}

export enum MIBStatus {
  success = 'success',
  error = 'error',
  noResponse = 'noResponse',
}

export type CustomMIBObject = {
  outcome: MIBOutcome;
  status: MIBStatus;
  rawResponse: string | undefined;
};
