export enum OutcomeCode {
  denied = 'denied',
  referred = 'referred',
  accepted = 'accepted',
  conditional = 'conditional',
  blocked = 'blocked',
  blockedAtQuoter = 'blockedAtQuoter',
  unknown = 'unknown',
  ignored = 'ignored',
}

export type OutcomeCodePerParticipant = { participantId: string; outcome: OutcomeCode };
