import * as z from 'zod';

import { documentsSchema } from './namespaces/documents';

export const mappingsPerNamespaceSchema = z.object({
  Documents: documentsSchema,
});

export type MappingsPerNamespace = z.infer<typeof mappingsPerNamespaceSchema>;
const optionalMappingsPerNamespaceSchema = mappingsPerNamespaceSchema.deepPartial();
export type PartialMappingsPerNamespace = z.infer<typeof optionalMappingsPerNamespaceSchema>;

export type Namespace = keyof z.infer<typeof mappingsPerNamespaceSchema>;
export function isMappingOf<TNamespace extends Namespace>(
  namespace: TNamespace,
  maybeMapping: unknown
): maybeMapping is keyof MappingsPerNamespace[TNamespace] {
  return mappingsPerNamespaceSchema.shape[namespace].keyof().safeParse(maybeMapping).success;
}

export function isNamespace(maybeNamespace: string): maybeNamespace is Namespace {
  return mappingsPerNamespaceSchema.keyof().safeParse(maybeNamespace).success;
}

export const Namespaces = mappingsPerNamespaceSchema.keyof().Enum;

// TODO replace the following with a Zod union of all namespace schema keys
export const MappingsSchema = mappingsPerNamespaceSchema.shape.Documents.keyof();
export type AnyMapping = z.infer<typeof MappingsSchema>;

export const NamespacesSchema = mappingsPerNamespaceSchema.keyof();
