type Party = {
  id: string;
  type: PartyType;
  communicationPreference?: string;
  legalEntityType?: string;
  language?: string;
  createdAt?: Date;
  updatedAt?: Date;
  address?: Address | null;
  communication?: Communication | null;
  person: Partial<Person> | null;
  organization: Partial<Organization> | null;
};

type FlattenedParty = Omit<Party, 'person' | 'organization'> & Partial<Person> & Partial<Organization>;

enum PartyType {
  person = 'PERSON',
  organization = 'ORGANIZATION',
}

type Person = {
  partyId: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  dob?: Date;
  citizenship?: string;
  gender?: string;
  ethnicity?: string;
  maritalStatus?: string;
  deathDate?: Date;
  suffix?: string;
  titlePrefix?: string;
  professionalDesignation?: string;
  createdAt?: Date;
  updatedAt?: Date;
};

type Organization = {
  partyId: string;
  orgType?: OrganizationType;
  legalEntityName: string;
  legalEntityOwnerName: string;
  status?: string;
  dissolutionName?: string;
  dissolutionDate?: Date;
  foundationDate?: Date;
  totalRevenue: string;
  createdAt?: Date;
  updatedAt?: Date;
};

enum OrganizationType {
  trust = 'TRUST',
  corporation = 'CORPORATION',
  soleProprietorship = 'SOLE_PROPRIETORSHIP',
  other = 'OTHER',
  entity = 'ENTITY',
}

type Address = {
  id?: string;
  partyId?: string;
  type?: string;
  stateOrProvince?: string;
  city?: string;
  postalCode?: string;
  line1?: string;
  line2?: string;
  country?: string;
  createdAt?: Date;
  updatedAt?: Date;
};

type Communication = {
  id?: string;
  partyId?: string;
  type?: string;
  phoneNumber?: string;
  phoneType?: string;
  email?: string;
  socialMediaId?: string;
  socialMediaType?: string;
  websiteUrl?: string;
  doNotContact?: boolean;
  isPrimary?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
};

export {
  Party as default,
  Party,
  FlattenedParty,
  FlattenedParty as Flattened,
  PartyType,
  PartyType as Type,
  Person,
  Organization,
  OrganizationType,
  Address,
  Communication,
};
