export enum PaymentMethod {
  card = 'card',
}

type CardPaymentMethodInfo = {
  type: PaymentMethod.card;
  last4: string;
};

// This will eventually be a union of different types of payment method responses such as card | acss | etc...
export type PaymentMethodInfo = CardPaymentMethodInfo;
