export enum UserRole {
  superAdmin = 'superAdmin', // Breathe Life internal user
  admin = 'admin', // Carrier admin user
  manager = 'manager', // Managing General Agent which manage a subset of advisors
  user = 'user', // Regular advisor / agent role
  viewer = 'viewer', // Read only role

  advisor = 'advisor', // LaCapitale specific - will be replaced with User
  support = 'support', // LaCapitale backoffice user

  // BL internal service roles
  cruncher = 'cruncher',
  pdfGenerator = 'pdfGenerator',
  messageQueue = 'messageQueue',
  consumerFlow = 'consumerFlow',
}

export const USER_ROLES_WITH_ASSIGNABLE_PERMISSION_VALUES: {
  [role in UserRolesWithAssignablePermissions]: UserRole;
} = {
  viewer: UserRole.viewer,
  user: UserRole.user,
  manager: UserRole.manager,
  admin: UserRole.admin,
  superAdmin: UserRole.superAdmin,
  support: UserRole.support,
};

export type UserRolesWithAssignablePermissions =
  | UserRole.superAdmin
  | UserRole.admin
  | UserRole.manager
  | UserRole.user
  | UserRole.viewer
  | UserRole.support;

export type ServiceRoles = UserRole.cruncher | UserRole.pdfGenerator | UserRole.messageQueue | UserRole.consumerFlow;
