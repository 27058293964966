import { DateUnit, ShiftMagnitude, TimeRoundingType } from './dateTime';

export enum QueryOperator {
  sum = 'sum',
  subtract = 'subtract',
  multiply = 'multiply',
  subtractDates = 'subtractDates',
  formula = 'formula',
  countEqual = 'countEqual',
  fetch = 'fetch',
  fetchSpecificId = 'fetchSpecificId',
  shiftDateBackwards = 'shiftDateBackwards',
}

export type QueryOperatorParameters = {
  numeric?: (number | string)[];
  formula?: string;
  formulaParams?: any;
  dateUnit?: DateUnit;
  controlValue?: unknown;
  fetch?: {
    firstNonEmptyValueAt: string[];
  };
  fetchSpecificId?: {
    specificValueAt: string;
    matchValue: string;
  };
  shiftDateParams?: {
    dateFormat: string;
    timeRoundingType: TimeRoundingType;
    shiftValue: number;
    shiftMagnitude: ShiftMagnitude;
  };
};

export type OperatorValue = number | string | boolean;
export type OperatorResult = number | string | boolean | undefined;

enum QueryOperatorNames {
  sum = 'sum',
  subtract = 'subtract',
  multiply = 'multiply',
  subtractDates = 'subtractDates',
  formula = 'formula',
  countEqual = 'countEqual',
  fetch = 'fetch',
  fetchSpecificId = 'fetchSpecificId',
  shiftDateBackwards = 'shiftDateBackwards',
}

type OperatorFunction = (values: Record<string, OperatorValue>, params?: QueryOperatorParameters) => OperatorResult;
export type QueryOperators = Record<QueryOperatorNames, OperatorFunction>;
