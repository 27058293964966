import { Language } from '../localization/localization';
import { DeepString } from '../utilities';

export type ComponentsTranslations = Partial<{
  advisorInformation: DeepString<Record<string, string>>;
  insuranceFirmInformation: DeepString<Record<string, string>>;
  ownerInformation: DeepString<Record<string, string>>;
  payorInformation: DeepString<Record<string, string>>;
  pdfTitle: DeepString<Record<string, string>>;
  productInformation: DeepString<Record<string, string>>;
  proposedInsuredInformation: DeepString<Record<string, string>>;
  submissionDate: DeepString<Record<string, string>>;
  carrierInformation: DeepString<Record<string, string>>;
  signature: DeepString<Record<string, Record<string, string>>>;
}>;

// TODO: Move from shared to local types once field-generated logic is moved DEV-10075
export type DynamicPdfFieldSettings = {
  displayAllOptions: boolean;
  displayFullDate?: boolean;
  optionDisplayLimit: number;
  variantOverride?: PdfVariant;
};

export type DynamicPdfTextOverrides = Partial<Record<Language, ComponentsTranslations>>; // TODO: Create dictionary for pdf localization

export enum DynamicPdfComponentName {
  AdvisorInformation = 'AdvisorInformation',
  OwnerInformation = 'OwnerInformation',
  PayorInformation = 'PayorInformation',
  ProposedInsuredInformation = 'ProposedInsuredInformation',
  SubmissionDate = 'SubmissionDate',
  InsuranceFirmInformation = 'InsuranceFirmInformation',
  PdfTitle = 'PdfTitle',
  ProductInformation = 'ProductInformation',
  Questionnaire = 'Questionnaire',
  CryptoSignature = 'CryptoSignature',
  ESignatureContainer = 'ESignatureContainer',
  CarrierInformation = 'CarrierInformation',
}

export enum PdfVariant {
  A = 'A',
  // Variant B was pretty much hardcoded for viaction since at that time, we had almost no time to come up with a solution.
  // This can now be removed, or at least translated since lots of stuff in there are in french directly.
  B = 'B',
}

export type PdfFieldVariant = {
  variant: PdfVariant;
  field: boolean; // TODO: FIX THIS
};

export type ComponentsVariantsTree = {
  advisorInformationVariant: PdfVariant;
  insuranceFirmInformationVariant: PdfVariant;
  ownerInformationVariant: PdfVariant;
  payorInformationVariant: PdfVariant;
  pdfTitleVariant: PdfVariant;
  productInformationVariant: PdfVariant;
  proposedInsuredInformationVariant: PdfVariant;
  submissionDateVariant: PdfVariant;
  carrierInformationVariant: PdfVariant;
};

export enum PdfFontWeight {
  bold = 600,
  normal = 400,
}

export type DynamicPdfComponentSettings = {
  variantOverride?: PdfVariant;
  enableSectionTitles?: boolean;
  displayAllOptions?: boolean;
  optionDisplayLimit?: number;
  displayFullDate?: boolean;
  fieldVariantOverride?: PdfFieldVariant[];
  alignFieldsTop?: boolean;
  avoidSubsectionPageBreak?: boolean;
  avoidFieldGroupPageBreak?: boolean;
  fontWeight?: PdfFontWeight;
  zeroCoverageMask?: string;
  enableOptionGroupMargin?: boolean;
};

export type DynamicPdfComponentSpecification = {
  name: DynamicPdfComponentName;
  settings?: DynamicPdfComponentSettings;
};

export type DynamicPdfComponentSelection = DynamicPdfComponentSpecification[];

export type DynamicPdfFieldProps = any;
export type DynamicPdfComponentProps = any;

/** This interface is specific to the dynamic-pdf and should not be used outside that domain. */
export interface IsSmokerParams {
  isTobaccoUser?: string;
  tobaccoMostRecentUsage?: string;
  marijuanaMostRecentUsage?: string;
}

/** This type is specific to the dynamic-pdf and should not be used outside that domain. */
export type IsSmoker = (props: IsSmokerParams) => boolean;

export interface DynamicPdfFunctionOverrides {
  isSmoker: IsSmoker;
}
