export const PartialColorRangesValues = [10, 20, 30, 40, 50, 60, 70, 80, 90, 'default'] as const;
export const FullColorRangesValues = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100] as const;

export type PartialColorGradient = Record<typeof PartialColorRangesValues[number], string>;
export type FullColorGradient = Record<typeof FullColorRangesValues[number], string>;

export type ColorRanges = {
  grey?: FullColorGradient;
  primary: PartialColorGradient;
  secondary: PartialColorGradient;
  tertiary: PartialColorGradient;
};
