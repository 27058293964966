export * from './namespaces';

export {
  mappingsPerNamespaceSchema,
  MappingsPerNamespace,
  PartialMappingsPerNamespace,
  isMappingOf,
  isNamespace,
  Namespace,
  Namespaces,
  MappingsSchema,
  NamespacesSchema,
  AnyMapping,
} from './mappingsPerNamespace';

export {
  entityMappingsFeatureConfigSchema,
  entityMappingsFeatureConfigJSONSchema,
  EntityMappingsConfig,
  AvailableEntityMappings,
} from './config';

export * from './mappings';
