import { DynamicPdfType } from '../api/dynamicPdf';
import { PdfDocumentType } from '../questionnaire';
import { FileTemplate } from './fileTemplate';

export enum StoredFileDocType {
  /** Dynamically generated insurance application PDF */
  ApplicationPdf = 'ApplicationPdf',

  /** Generic attachment type */
  Attachment = 'Attachment',

  /** Dynamically generated advisor's report */
  AdvisorsReport = 'AdvisorsReport',

  /** Insurance policy illustration */
  Illustration = 'Illustration',

  /** Dynamically generated report for the carrier (only used in BNC, BNC travel) */
  CarrierReport = 'CarrierReport',

  /** Needs analysis PDF file (was created for Viaction (deprecated carrier)) */
  NeedsAnalysisPdf = 'NeedsAnalysisPdf',

  /** Evidence summary of eSign ceremony generated by OneSpan */
  OneSpanEvidenceSummary = 'OneSpanEvidenceSummary',

  /** Application Marketing Data extracted in CSV file */
  ApplicationMarketingDataExtractCSV = 'ApplicationMarketingDataExtractCSV',
}

export const DynamicPdfTypeToDocTypeLookup: { [key in DynamicPdfType]: StoredFileDocType } = {
  [DynamicPdfType.insured]: StoredFileDocType.ApplicationPdf,
  [DynamicPdfType.advisor]: StoredFileDocType.AdvisorsReport,
  [DynamicPdfType.carrier]: StoredFileDocType.CarrierReport,
};

export const PdfTypeToDocTypeLookup: Record<PdfDocumentType, StoredFileDocType> = {
  [PdfDocumentType.application]: StoredFileDocType.ApplicationPdf,
  [PdfDocumentType.advisorsReport]: StoredFileDocType.AdvisorsReport,
  [PdfDocumentType.carrierReport]: StoredFileDocType.CarrierReport,
  [PdfDocumentType.external]: StoredFileDocType.NeedsAnalysisPdf,
  [PdfDocumentType.oneSpanEvidenceSummary]: StoredFileDocType.OneSpanEvidenceSummary,
};

export type StoredFile = {
  id: string;
  docType: StoredFileDocType;
  applicationId: string | null;
  templateId: string | null;
  displayName: string;
  mimeType: string;
  encoding: string;
  sizeBytes: number | null;
  storageKey: string | null;
  storageBucket: string | null;
  lastUsedAt: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateExternalApplicationsFilesResponse = {
  fileIds: string[];
};

export type StoredFileWithSignedUrl = StoredFile & { signedUrl: string };
export type StoredFileWithBuffer = StoredFile & { buffer: Buffer };

export type ExpandableStoredFile<T extends StoredFile = StoredFile> = T & {
  fileTemplate?: FileTemplate | null;
};
