import { DataLabel } from '@breathelife/meta-cruncher';

import { InsuranceScopes } from '../applications/insuranceScopes';
import { IsoCountryCode } from '../applications/isoCountryCode';
import { ApplicationMode } from '../applications/mode';
import { IconName } from '../icons';
import { Localizable } from '../localization';
import { ParticipantRoles } from '../participant';
import { PdfDocumentType } from '../questionnaire/documentType';
import { EngineEffects } from '../questionnaire/engineEffects';
import { FieldLayout } from '../questionnaire/fieldLayout';
import { InsuranceModule } from '../questionnaire/insuranceModule';
import { OptionSize } from '../questionnaire/optionSize';
import { PlatformType } from '../questionnaire/platformType';
import { SubsectionVariant } from '../questionnaire/subsectionVariant';
import { BlueprintConditionsValueWithMessage, BlueprintConditionValue } from './conditionBlueprints';
import { QuoterSubsectionBlueprintVariant, SubsectionVariantBlueprint } from './subsectionVariantBlueprints';

export type DynamicOptionsBlueprint = {
  collection: string;
  select: string[];
  visible?: BlueprintConditionValue;
};

export type FieldBlueprintBase = QuestionnaireBlueprintBase & {
  answerNodeId: string;
  fieldType: QuestionnaireBlueprintFieldTypes;
  valid?: BlueprintConditionsValueWithMessage[];
  optional?: boolean;
  disabled?: boolean;
  infoSupplement?: InfoSupplementBlueprint;
  placeholder?: Partial<Localizable>;
  iconName?: IconName;
  triggerStepNavigation?: boolean;
  layout?: FieldLayout;
  displayInCardPreview?: boolean;
  dynamicOptions?: DynamicOptionsBlueprint;
  internal?: {
    relatesTo?: string;
    computedValue?: Record<string, unknown>; // TODO update typing when we add support for queries in parts
  };
  effects?: EngineEffects;
  applicationModes?: ApplicationMode[];
};

export type InputFieldBlueprint = FieldBlueprintBase & {
  validateAs: InputFieldValidation;
  defaultValue?: string;
};

export type NumberFieldBlueprint = FieldBlueprintBase & {
  validateAs: NumberFieldValidation;
  numericalDataType?: 'float' | 'integer';
  defaultValue?: number;
};

export type MoneyFieldBlueprint = FieldBlueprintBase & {
  validateAs: MoneyFieldValidation;
  defaultValue?: number;
};

export type DateFieldBlueprint = FieldBlueprintBase & {
  validateAs: DateFieldValidation;
};

export type CheckboxFieldBlueprint = FieldBlueprintBase & {
  validateAs: BooleanFieldValidation;
  defaultValue?: boolean;
};

export type TextareaFieldBlueprint = FieldBlueprintBase & {
  validateAs: StringFieldValidation;
  defaultValue?: string;
};

export type PhoneFieldBlueprint = FieldBlueprintBase & {
  validateAs: PhoneFieldValidation;
};

export type InformationFieldBlueprint = FieldBlueprintBase & {
  validateAs: StringFieldValidation;
  variant: InformationFieldBlueprintVariant;
};

export type ButtonFieldBlueprint = FieldBlueprintBase & {
  validateAs: BooleanFieldValidation;
  buttonText: Partial<Localizable>;
};

export type CurrencyCardFieldBlueprint = FieldBlueprintBase & {
  validateAs: NumberFieldValidation;
};

export type InfoSupplementBlueprint = {
  text: Partial<Localizable>;
  title?: Partial<Localizable>;
  image?: {
    // This is the only image we support right now, and it only works for certain carriers...
    name: 'cheque';
  };
};

export type AddressAutocompleteFieldBlueprint = FieldBlueprintBase & {
  validateAs: StringFieldValidation;
  countryCode: IsoCountryCode;

  // When the `addressAutocompleteFields.streetAddress` is not set we still need to store the autocomplete data somewhere.
  addressAutocompleteNodeId?: string;

  // Store the partNames of the blueprints associated with this addressAutocomplete blueprint.
  addressAutocompleteFields: {
    streetAddress?: string;
    city: string;
    stateOrProvince: string;
    postalCodeOrZip: string;
  };
};

export type RadioFieldBlueprint = FieldBlueprintBase & {
  validateAs: StringFieldValidation | BooleanFieldValidation;
  selectOptions: SelectOptionBlueprint[];
  optionSize?: keyof typeof OptionSize;
  defaultValue?: string | boolean;
};

export type AgreeFieldBlueprint = FieldBlueprintBase & {
  validateAs: BooleanFieldValidation;
  confirmedLabel: Partial<Localizable>;
  modalHeader: Partial<Localizable>;
  modalText: Partial<Localizable>;
};

export type SignatureFieldBlueprint = FieldBlueprintBase & {
  validateAs: AlwaysValid.signature;
  participantRole: ParticipantRoles;
};

export type SectionGroupKey = 'insuredPeople' | 'contract';

export type QuestionnaireBlueprintBase = {
  id: string;
  partName: string;
  text?: Partial<Localizable>;
  title?: Partial<Localizable>;
  visible?: BlueprintConditionValue;
  dataLabel?: DataLabel;
  platforms?: PlatformType[];
  renderOn?: QuestionnaireBlueprintRenderOn[];
  scopes?: InsuranceScopes[];
  hidden?: boolean;
  isCustom?: boolean;
  copyable?: QuestionnaireBlueprintCopyableOption;
};

export type QuestionnaireBlueprint = {
  sectionBlueprints: SectionBlueprint[];
  sectionGroupBlueprints: Partial<Record<SectionGroupKey, SectionGroupBlueprint>>;
};

export type SectionGroupBlueprint = QuestionnaireBlueprintBase & {
  repeatable?: {
    repeatableAnswerNodeId: string;
    minRepeatable: number;
    maxRepeatable: number;
  };
  effects?: EngineEffects;
};

export type SectionBlueprint = QuestionnaireBlueprintBase & {
  sectionGroupKey: SectionGroupKey;
  subsections: SubsectionBlueprint[];
  pdfDocuments?: PdfDocumentType[];
  modules?: InsuranceModule[];
  iconName?: IconName;
};

export type SubsectionBlueprint = QuestionnaireBlueprintBase & {
  questions: QuestionBlueprint[];
  nextStepButtonText?: Partial<Localizable>;
  variant?: SubsectionVariantBlueprint;
  iconName?: IconName;
  internal?: {
    variant?: SubsectionVariant;
    variantOptions?: { useApplicationMonthlyPremium?: boolean };
  };
  showInNavigation?: boolean;
  pageBreakSubSectionInPdf?: boolean;
};

export type QuoterSubsectionBlueprint = SubsectionBlueprint & {
  variant: QuoterSubsectionBlueprintVariant;
};

export type QuestionBlueprint = QuestionnaireBlueprintBase & {
  fields: FieldBlueprint[];
  repeatable?: {
    repeatableAnswerNodeId: string;
    addButtonText: Partial<Localizable>;
    removeButtonText: Partial<Localizable>;
    minRepeatable: number;
    maxRepeatable: number;
  };
  internal?: { showInPdfWithoutFields?: boolean };
  displayAsCard?: boolean;
  effects?: EngineEffects;
};

export enum NumberFieldValidation {
  integer = 'integer',
  decimal = 'decimal',
  percentage = 'percentage',
}

export enum MoneyFieldValidation {
  integer = 'integer',
  decimal = 'decimal',
}

export enum DateFieldValidation {
  date = 'date',
  pastDate = 'pastDate',
  yearMonth = 'yearMonth',
  yearMonthPastDate = 'yearMonthPastDate',
  yearMonthFutureDate = 'yearMonthFutureDate',
  yearMonthPastOrCurrentDate = 'yearMonthPastOrCurrentDate',
  yearMonthFutureOrCurrentDate = 'yearMonthFutureOrCurrentDate',
  futureDate = 'futureDate',
  futureOrCurrentDate = 'futureOrCurrentDate',
  pastOrCurrentDate = 'pastOrCurrentDate',
  currentDate = 'currentDate',
}

export enum BooleanFieldValidation {
  boolean = 'boolean',
  booleanTrue = 'booleanTrue',
}

export enum StringFieldValidation {
  string = 'string',
}

export enum PhoneFieldValidation {
  phone = 'phone',
}

export enum InputFieldValidation {
  string = 'string',
  email = 'email',
  firstName = 'firstName',
  lastName = 'lastName',
  sin = 'sin',
  canadianPostalCode = 'canadianPostalCode',
  zipCode = 'zipCode',
  branchNumber = 'branchNumber',
  institutionNumber = 'institutionNumber',
  accountNumber = 'accountNumber',
  ssn = 'ssn',
}

export enum AlwaysValid {
  signature = 'signature',
}

// TODO: move FieldTypes out of shared/questionnaire and use that.
export enum QuestionnaireBlueprintFieldTypes {
  input = 'input',
  textarea = 'textarea',
  radio = 'radio',
  dropdown = 'dropdown',
  checkboxGroup = 'checkboxGroup',
  checkbox = 'checkbox',
  date = 'date',
  number = 'number',
  money = 'money',
  phone = 'phone',
  information = 'information',
  button = 'button',
  currencyCard = 'currencyCard',
  autocomplete = 'autocomplete',
  agree = 'agree',
  signature = 'signature',
}

// TODO: move InfoVariants out of shared/questionnaire and use that.
export enum InformationFieldBlueprintVariant {
  warning = 'warning',
  error = 'error',
  success = 'success',
  info = 'info',
  paragraph = 'paragraph',
}

export enum QuestionnaireBlueprintRenderOn {
  web = 'web',
  pdf = 'pdf',
  summary = 'summary',
}

export enum QuestionnaireBlueprintCopyableOption {
  none = 'none',
  insured = 'insured',
  owner = 'owner',
}

export type SelectOptionFieldBlueprint = FieldBlueprintBase & {
  selectOptions: SelectOptionBlueprint[];
  validateAs: StringFieldValidation;
  defaultValue?: string | string[];
};

export type FieldBlueprint =
  | InputFieldBlueprint
  | SelectOptionFieldBlueprint
  | NumberFieldBlueprint
  | MoneyFieldBlueprint
  | DateFieldBlueprint
  | CheckboxFieldBlueprint
  | TextareaFieldBlueprint
  | PhoneFieldBlueprint
  | InformationFieldBlueprint
  | ButtonFieldBlueprint
  | CurrencyCardFieldBlueprint
  | AddressAutocompleteFieldBlueprint
  | RadioFieldBlueprint
  | AgreeFieldBlueprint
  | SignatureFieldBlueprint;

export type FieldValidation =
  | InputFieldValidation
  | NumberFieldValidation
  | MoneyFieldValidation
  | DateFieldValidation
  | BooleanFieldValidation
  | StringFieldValidation
  | AlwaysValid;

export type QuestionnaireElementBlueprint = SectionBlueprint | SubsectionBlueprint | QuestionBlueprint | FieldBlueprint;

export function isSectionGroupKey(data: unknown): data is SectionGroupKey {
  return typeof data === 'string' && ['insuredPeople', 'contract'].some((e) => e === data);
}

export function isSectionBlueprint(
  questionnaireElementBlueprint: QuestionnaireElementBlueprint | SelectOptionBlueprint
): questionnaireElementBlueprint is SectionBlueprint {
  return !!(questionnaireElementBlueprint as SectionBlueprint).subsections;
}

export function isSubsectionBlueprint(
  questionnaireElementBlueprint: QuestionnaireElementBlueprint | SelectOptionBlueprint
): questionnaireElementBlueprint is SubsectionBlueprint {
  return !!(questionnaireElementBlueprint as SubsectionBlueprint).questions;
}

export function isQuestionBlueprint(
  questionnaireElementBlueprint: QuestionnaireElementBlueprint | SelectOptionBlueprint
): questionnaireElementBlueprint is QuestionBlueprint {
  return !!(questionnaireElementBlueprint as QuestionBlueprint).fields;
}

export function isFieldBlueprint(
  questionnaireElementBlueprint: QuestionnaireElementBlueprint | SelectOptionBlueprint
): questionnaireElementBlueprint is FieldBlueprint {
  return !!(questionnaireElementBlueprint as FieldBlueprint).fieldType;
}

export function isSelectOptionBlueprint(
  questionnaireElementBlueprint: QuestionnaireElementBlueprint | SelectOptionBlueprint
): questionnaireElementBlueprint is SelectOptionBlueprint {
  return (
    !isSectionBlueprint(questionnaireElementBlueprint) &&
    !isSubsectionBlueprint(questionnaireElementBlueprint) &&
    !isQuestionBlueprint(questionnaireElementBlueprint) &&
    !isFieldBlueprint(questionnaireElementBlueprint)
  );
}

export type SelectOptionBlueprint = {
  partName: string;
  hidden?: boolean;
  isCustom?: boolean;
  text: Partial<Localizable>;
  visible?: BlueprintConditionValue;
  internal?: {
    scopeFilter?: InsuranceScopes[];
    platformTypeFilter?: PlatformType[];
    checked?: boolean;
  };
};

export function isSelectOptionFieldBlueprint(
  fieldBlueprint: FieldBlueprint
): fieldBlueprint is SelectOptionFieldBlueprint {
  return !!(fieldBlueprint as SelectOptionFieldBlueprint).selectOptions;
}

export function isButtonFieldBlueprint(fieldBlueprint: FieldBlueprint): fieldBlueprint is ButtonFieldBlueprint {
  return fieldBlueprint.fieldType === QuestionnaireBlueprintFieldTypes.button;
}

export function isInformationFieldBlueprint(
  fieldBlueprint: FieldBlueprint
): fieldBlueprint is InformationFieldBlueprint {
  return fieldBlueprint.fieldType === QuestionnaireBlueprintFieldTypes.information;
}

export function isAddressAutocompleteFieldBlueprint(
  fieldBlueprint: FieldBlueprint
): fieldBlueprint is AddressAutocompleteFieldBlueprint {
  return fieldBlueprint.fieldType === QuestionnaireBlueprintFieldTypes.autocomplete;
}

export function isRadioFieldBlueprint(fieldBlueprint: FieldBlueprint): fieldBlueprint is RadioFieldBlueprint {
  return fieldBlueprint.fieldType === QuestionnaireBlueprintFieldTypes.radio;
}

export function isAgreeFieldBlueprint(fieldBlueprint: FieldBlueprint): fieldBlueprint is AgreeFieldBlueprint {
  return fieldBlueprint.fieldType === QuestionnaireBlueprintFieldTypes.agree;
}

export function isNumberFieldBlueprint(fieldBlueprint: FieldBlueprint): fieldBlueprint is NumberFieldBlueprint {
  return fieldBlueprint.fieldType === QuestionnaireBlueprintFieldTypes.number;
}

export function isInputFieldBlueprint(fieldBlueprint: FieldBlueprint): fieldBlueprint is InputFieldBlueprint {
  return fieldBlueprint.fieldType === QuestionnaireBlueprintFieldTypes.input;
}

export function isMoneyFieldBlueprint(fieldBlueprint: FieldBlueprint): fieldBlueprint is MoneyFieldBlueprint {
  return fieldBlueprint.fieldType === QuestionnaireBlueprintFieldTypes.money;
}

export function isTextareaFieldBlueprint(fieldBlueprint: FieldBlueprint): fieldBlueprint is TextareaFieldBlueprint {
  return fieldBlueprint.fieldType === QuestionnaireBlueprintFieldTypes.textarea;
}

export function isCheckboxFieldBlueprint(fieldBlueprint: FieldBlueprint): fieldBlueprint is CheckboxFieldBlueprint {
  return fieldBlueprint.fieldType === QuestionnaireBlueprintFieldTypes.checkbox;
}

export function isSignatureFieldBlueprint(fieldBlueprint: FieldBlueprint): fieldBlueprint is SignatureFieldBlueprint {
  return fieldBlueprint.fieldType === QuestionnaireBlueprintFieldTypes.signature;
}

export function isQuoterSubsectionBlueprint(subsection: SubsectionBlueprint): subsection is QuoterSubsectionBlueprint {
  const quoterSubsection = subsection as QuoterSubsectionBlueprint;
  return quoterSubsection.variant?.type === SubsectionVariant.quoter && !!quoterSubsection.variant?.productNodeIds;
}

export function isTextFieldBlueprint(fieldType: QuestionnaireBlueprintFieldTypes): boolean {
  const textFieldTypes = [
    QuestionnaireBlueprintFieldTypes.input,
    QuestionnaireBlueprintFieldTypes.textarea,
    QuestionnaireBlueprintFieldTypes.money,
    QuestionnaireBlueprintFieldTypes.phone,
    QuestionnaireBlueprintFieldTypes.dropdown,
    QuestionnaireBlueprintFieldTypes.number,
    QuestionnaireBlueprintFieldTypes.autocomplete,
    QuestionnaireBlueprintFieldTypes.date,
  ];
  return textFieldTypes.includes(fieldType);
}

export type AcceptingDefaultValueFieldBlueprint =
  | CheckboxFieldBlueprint
  | TextareaFieldBlueprint
  | InputFieldBlueprint
  | MoneyFieldBlueprint
  | SelectOptionFieldBlueprint
  | NumberFieldBlueprint
  | RadioFieldBlueprint;

export function isAcceptingDefaultValueField(
  fieldBlueprint: FieldBlueprint
): fieldBlueprint is AcceptingDefaultValueFieldBlueprint {
  return (
    !!(fieldBlueprint as AcceptingDefaultValueFieldBlueprint).defaultValue ||
    isCheckboxFieldBlueprint(fieldBlueprint) ||
    isTextareaFieldBlueprint(fieldBlueprint) ||
    isInputFieldBlueprint(fieldBlueprint) ||
    isMoneyFieldBlueprint(fieldBlueprint) ||
    isSelectOptionFieldBlueprint(fieldBlueprint) ||
    isNumberFieldBlueprint(fieldBlueprint) ||
    isRadioFieldBlueprint(fieldBlueprint)
  );
}
